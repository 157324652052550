(function ($) {
  var results = $('.js-search-results');
  var reset = $('.js-search-reset');
  var submit = $('.js-search-submit');
  var loader = $('.js-search-loader');

  $('.js-search-input').keyup(function (e) { 
    if ($(this).val().length > 0) {
      reset.removeClass('d-none');
    } else {
      reset.addClass('d-none');
      submit.addClass('d-none');
    }

    if ($(this).val().length > 2) {
      search();
    } else {
      results.addClass('d-none');
      submit.addClass('d-none');
    }
  });

  $('#search-form').on('reset', function () {
    results.addClass('d-none');
    reset.addClass('d-none');
    submit.addClass('d-none');

    if(typeof xhr !== "undefined" && typeof xhr.abort !== "undefined") {
	    xhr.abort();
    }
  });

  function search() {
    if(typeof xhr !== "undefined" && typeof xhr.abort !== "undefined") {
	    xhr.abort();
    }
    
    xhr = $.ajax({
			url: vars.ajax_url,
			type: 'GET',
			dataType: 'json',
			data: {
				action: 'search_results',
        data: $('#search-form').serialize()
			},
			beforeSend: function() {
        loader.removeClass('d-none');
        submit.addClass('d-none');
        results.addClass('d-none').find('.row').empty();
			},
      success: function (res) {
				results.removeClass('d-none').find('.row').append(res.html);
        loader.addClass('d-none');

        if (res.more) {
          submit.removeClass('d-none');
        }
      }
		});
  }
})(jQuery);