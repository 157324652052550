(function ($) {
  $('#video').on('shown.bs.modal', function (e) {
    var video = $(e.currentTarget).find('video').get(0);
    video.play();
  }).on('hide.bs.modal', function (e) {
    var video = $(e.currentTarget).find('video').get(0);
    video.pause();
  });

  $('.header__menu .sub-menu > li > a figure').each(function (i, e) {
    $(e).insertBefore($(e).parent());
  });

  $('.header__menu .menu-item-has-children > a').on('touchstart mouseenter', function (e) {
    var li = $(this).parent();

    if (!li.hasClass('sub-menu-opened')) {
      li.addClass('sub-menu-opened');
      e.preventDefault();
    }
  });

  $(".js-sticky-sidebar").stick_in_parent({
    offset_top: $('.header').outerHeight(),
    recalc_every: 1
  });

  smoothScrolling('.js-anchor');

  function smoothScrolling(selector, offset_top) {

    offset_top = (offset_top) ? offset_top : 0;
    
    $(selector + '[href^="#"]:not([href="#"])').click(function() {
      var target = $(this.hash);

      if ( target.length ) {
        $('html, body').animate({
          scrollTop: target.offset().top - offset_top - $('.header').outerHeight()
        }, 600);

        return false;
      }
    });
  }
})(jQuery);